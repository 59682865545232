/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import atitle from '@/components/Approve/atitle';
import emptyTxt from '@/views/Approval/emptyTxt';
export default {
  components: {
    atitle: atitle,
    emptyTxt: emptyTxt
  },
  data: function data() {
    return {
      copySpanName: ['未抄送', '已抄送'],
      isAuditName: ['未审核', '审核中', '已同意', '已拒绝'],
      //审核的状态名
      subName: ['未审核', '审核中', '已同意', '已拒绝', '发起请求'],
      isAuditColor: ['#999', '#FFB033', '#79D012', '#ff0000', '#3399FF'],
      //审核的状态颜色
      wayName: ['', '会签', '或签', '依次签'],
      nodelist: [],
      //审核节点
      ApprObj: {},
      // 申请人节点
      userLists: _toConsumableArray("1223"),
      currentLevel: 0,
      //当前到达节点
      copyNode: [],
      //抄送的节点
      copyflag: 0,
      //0 没到 1 到了
      endOverTime: "",
      //最后的时间
      showAudit: false,
      //是否展示列表
      sindex: 0 //展开的索引

    };
  },
  props: {
    node: Object
  },
  watch: {
    node: {
      //深度监听父组件的list
      handler: function handler(newVal) {
        var _this = this;

        this.currentLevel = newVal.currentLevel;
        this.endOverTime = newVal.lastModifyTime;

        if (newVal.qaList) {
          this.nodelist = newVal.qaList;

          if (this.nodelist[this.nodelist.length - 1].level == this.currentLevel && this.nodelist[this.nodelist.length - 1].auditStatus == 2) {
            this.copyflag = 1;
          } else {
            this.copyflag = 0;
          }

          this.nodelist.some(function (item, i) {
            //有没有通过的或者拒绝的
            item.opflag = _this.ifOpList(item, i);
          });
        }

        if (newVal.applyPersonVo) {
          this.ApprObj = newVal.applyPersonVo;
        }

        if (newVal.makeCopys) {
          this.copyNode = newVal.makeCopys;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 点击展开的
    showClickItem: function showClickItem(index) {
      if (this.sindex == index) {
        this.showAudit = !this.showAudit;
      } else {
        this.sindex = index;
        this.showAudit = true;
      }
    },
    // 判断list中有没有审批意见  lwt 2020-07-08
    ifOpList: function ifOpList(item, i) {
      var list = [];
      item.persionList.some(function (sitem) {
        if (sitem.status == 2 || sitem.status == 3) {
          list.push(sitem);
        }
      });

      if (list.length > 0) {
        this.nodelist[i].opList = list;
        return true;
      } else {
        return false;
      }
    }
  }
};